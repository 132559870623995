<div class="mb-4">
  <div
    class="cta d-flex align-items-center justify-content-between text-white bg-black rounded px-4 cursor-pointer"
    (click)="openChangeEventModal()"
  >
    <fgb-event-header [event]="selectedEvent()"></fgb-event-header>
    <div>
      <button
        class="text-white font-default font-size-24 d-flex align-items-center btn p-3"
        [attr.aria-label]="'change.event.loaded.tickets' | translate"
      >
        <span class="text-white font-default-bold icon material-icons text-danger" aria-hidden="true">cached</span>
        <span class="fw-bold d-lg-block d-none ps-2">{{ 'change.event.loaded.tickets' | translate }}</span>
      </button>
    </div>
  </div>
</div>
