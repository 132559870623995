import { Component, OnInit } from '@angular/core';
import { AnnouncementService } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'fgb-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  campaignOffersList = [];
  screenType = ScreenType;

  constructor(private announcementService: AnnouncementService) {}

  ngOnInit() {
    firstValueFrom(this.announcementService.fetchAnnouncements());
  }

  onCampaignOfferListChange(e: any) {
    this.campaignOffersList = e?.CampaignsAndEntitlements || [];
  }
}
