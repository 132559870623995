<div class="bg-black h-100 d-flex justify-content-center align-items-center flex-column">
  <img class="logo mb-4" draggable="false" src="assets/images/logo.svg" />

  <div
    class="text-white font-size-14 text-center login-desc mb-4"
    [innerHTML]="'login.text.description' | translate | markdownTranslate"
  ></div>
  <div class="bg-white card">
    <ng-container>
      <div class="w-90">
        <fgb-tm-user-login></fgb-tm-user-login>
      </div>
    </ng-container>
  </div>
</div>
