import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {
  CartQuery,
  Cart,
  ErrorStateService,
  MemberDetails,
  MemberQuery,
  MemberService,
  CardTokenService,
  MarketplaceItemCart,
  CardTokenQuery,
  MarketplaceService,
  CartService,
} from '@fgb/core';
import { firstValueFrom, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'fgb-cart-checkout-page',
  templateUrl: './cart-checkout-page.component.html',
  styleUrls: ['./cart-checkout-page.component.scss'],
})
export class CartCheckoutPageComponent implements OnInit {
  totalPoints: number;
  totalItems$: Observable<number>;
  items$: Observable<Cart[] | undefined>;
  editingMemberAddress: boolean = false;
  missingDetails: string = '';
  memberDetail$: Observable<MemberDetails | undefined>;
  memberAddressForm: FormGroup;
  portalId = '';
  hasSubmittedMemberAddress: boolean = false;
  memberDetails: MemberDetails;
  cardInfor: any = {};
  statusText: string;
  validCheckout: boolean;
  isListHasMerchantItem: boolean = false;
  totalPriceMerchantValue: number = 0;
  orderId: string = '';
  listItems: any[] = [];
  convertListItems: any[] = [];
  loading: boolean = false;

  constructor(
    private location: Location,
    private cartQuery: CartQuery,
    private errorService: ErrorStateService,
    private memberQuery: MemberQuery,
    private formBuilder: FormBuilder,
    private memberService: MemberService,
    private cardTokenService: CardTokenService,
    private router: Router,
    private cardTokenQuery: CardTokenQuery,
    private marketplaceService: MarketplaceService,
    private cartService: CartService
  ) {}

  ngOnInit() {
    this.cardTokenService.fetchCardTokens(true).subscribe();

    this.cardTokenQuery.selectUnfrozenCards().subscribe((tokens: any) => {
      if (tokens.length > 0) {
        this.cardInfor = { ...tokens[0] };
      }
    });

    this.errorService.clearErrors();

    firstValueFrom(
      this.cartQuery.updateBasketPointsTotal().pipe(
        tap((val: any) => {
          this.totalPoints = val && !isNaN(val) ? val : 0;
        })
      )
    );

    this.items$ = this.cartQuery.selectAllMarketplaceCartData().pipe(
      tap((items: any) => {
        if (items) {
          const specificMerchantItem = items.find((item: MarketplaceItemCart) => item.productData.MerchantValue);
          if (specificMerchantItem && specificMerchantItem.productData.MerchantValue) {
            this.isListHasMerchantItem = true;
            this.totalPriceMerchantValue = items.reduce((prev: any, cur: any) => {
              return prev + cur.totalPrice;
            }, 0);
          }
          const orderBody = items.map((item: MarketplaceItemCart) => {
            return {
              Id: item.productData.Id,
              RewardItemId: item.productData.ProductItemId,
              GameNumber: item.productData.GameNumber,
              AttributeMappingId: item.attributes.AttributeMappingId,
              Quantity: item.purchaseQuantity,
              CustomFields: item.customFields,
            };
          });

          this.marketplaceService.checkoutStart(orderBody).then((res) => {
            if (res.OrderId) {
              this.orderId = res.OrderId;
            }
          });
        }
      })
    );
    this.totalItems$ = this.cartQuery.updateTotalItems();

    // get address from member details
    this.getMemberDetailAddress();
  }

  ngOnDestroy(): void {
    this.cartService.removeAllCartItems();
  }

  // go back to previous page from checkout page
  goBack() {
    this.location.back();
  }

  // make payment on checkout page, show error if member address data is invalid
  async payNow() {
    try {
      this.loading = true;
      const res = await this.marketplaceService.completePurchaseBasketOrder(
        this.orderId,
        '/checkout',
        '/rewards/marketplace/purchase/success',
        !!this.cardInfor.CardNumber
      );

      const orderId = res?.Data?.OrderId || '';
      if (orderId) {
        this.router.navigate([`rewards/marketplace/purchase/success`], { queryParams: { purchaseOrderId: orderId } });
      }
    } catch (err: any) {
      this.loading = false;
    }
  }

  /** Start editing the user's member details address. */
  editMemberAddress() {
    this.editingMemberAddress = true;
  }

  /** Clear the member details address form fields. */
  clearAddressForm(addressForm: FormGroup) {
    addressForm.patchValue({
      Street: '',
      Town: '',
      County: '',
      PostCode: '',
      Country: '',
      HomeNumber: '',
    });
  }

  // cancel button which will close and rebuild the form with the original data
  cancelAndRebuildForm() {
    this.editingMemberAddress = false;

    this.memberDetail$ = this.memberQuery.selectMemberDetails().pipe(
      tap((md) => {
        if (md) {
          this.portalId = md.PortalId;
          this.memberAddressForm = this.formBuilder.group({
            AddressName: [md.AddressName],

            Street: [md.Street, Validators.required],
            Town: [md.Town, Validators.required],
            County: [md.County, Validators.required],
            Country: [md.Country, Validators.required],
            PostCode: [md.PostCode, Validators.required],
            HomeNumber: [md.HomeNumber, Validators.required],
          });
        }
      })
    );
  }

  getMemberDetailAddress() {
    this.memberDetail$ = this.memberQuery.selectMemberDetails().pipe(
      tap((md) => {
        if (md) {
          this.memberDetails = md;
          this.portalId = md.PortalId;
          this.memberAddressForm = this.formBuilder.group({
            AddressName: [md.AddressName],

            Street: [md.Street, Validators.required],
            Town: [md.Town, Validators.required],
            County: [md.County, Validators.required],
            Country: [md.Country, Validators.required],
            PostCode: [md.PostCode, Validators.required],
            HomeNumber: [md.HomeNumber, Validators.required],
          });
        }
      })
    );
  }

  saveMemberDetailAddress() {
    this.hasSubmittedMemberAddress = true;

    if (this.memberAddressForm.valid) {
      this.memberDetails = { ...this.memberDetails, ...this.memberAddressForm.value };

      this.memberService.updateMemberDetails(this.portalId, this.memberDetails);
      this.editingMemberAddress = false;
      this.errorService.clearErrors();
    }
  }

  moveToPayment() {
    this.router.navigate([`payment`]);
  }
}
