import { Component, Input, OnInit } from '@angular/core';
import { ProcessProviderId, RewardsWalletItem, RewardsWalletQuery } from '@fgb/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-wallet-claimed-page',
  templateUrl: './wallet-claimed-page.component.html',
  styleUrls: ['./wallet-claimed-page.component.scss'],
})
export class WalletClaimedPageComponent implements OnInit {
  @Input() marketplaceFilterTypes: ProcessProviderId[] = [0, 1, 2, 3, 4, 5];
  walletItems$: Observable<RewardsWalletItem[]>;

  walletItemsClaimed$: Observable<RewardsWalletItem[]>;

  constructor( private rewardsWalletQuery: RewardsWalletQuery) {}

  ngOnInit() {
    this.walletItemsClaimed$ = this.rewardsWalletQuery.selectRewardWalletItemsByStatus(true);
  }
}
