<div class="container">
  <div class="back-mobile-bar d-flex justify-content-between align-items-center d-lg-none mt-2">
    <!-- Back Button -->
    <button class="btn btn-text-go-back btn-text-go-back-sm fw-bold d-flex d-lg-none align-items-center ps-1" (click)="goBack()">
      <span class="font-size-14 fw-bold text-danger">{{ 'checkout.go.back' | translate }}</span>
    </button>
    <!-- Checkout Title -->
    <div class="">
      <div class="mb-0 text-secondary font-size-14 text-uppercase checkout-title">{{ 'checkout.banner' | translate }}</div>
    </div>
  </div>
  <div class="row mx-lg-4 mt-lg-4">
    <div class="col-lg-7 col-12 mt-3">
      <h4 class="text-black fw-bold item-summary-title mb-3">{{ 'cart.itemsSummary' | translate }}</h4>

      <fgbcl-cart-item></fgbcl-cart-item>
      <button class="btn btn-text-go-back fw-bold d-none d-lg-flex align-items-center ps-0 mt-lg-3" (click)="goBack()">
        <span class="font-size-14 text-danger">{{ 'checkout.go.back' | translate }}</span>
      </button>
    </div>
    <div class="col-lg-5 col-12 mt-lg-3 mt-4">
      <ng-container *ngIf="editingMemberAddress">
        <div class="address-form container mb-3">
          <div class="position-relative">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <div class="text-primary font-size-14 fw-bold">{{ 'checkout.address' | translate }}</div>
              <a
                (click)="clearAddressForm(memberAddressForm)"
                class="text-danger heading-text-one font-size-14 cursor-pointer fw-bold"
                >{{ 'checkout.clear.form' | translate }}</a
              >
            </div>

            <div class="d-flex justify-content-between my-2 font-size-14">
              <button class="btn btn-primary fw-bold btn-cancel-save" (click)="cancelAndRebuildForm()">
                {{ 'checkout.address_form_cancel_btn' | translate }}
              </button>
              <button class="btn btn-success fw-bold btn-cancel-save" (click)="saveMemberDetailAddress()">
                {{ 'checkout.address_form_save_btn' | translate }}
              </button>
            </div>
          </div>
        </div>
      </ng-container>
      <!--EO edit member details address-->
      <fgbcl-errors></fgbcl-errors>

      <!--summary of items in basket-->
      <div class="container address-form mb-3">
        <div class="text-black font-size-14 fw-bold mb-summary">{{ 'checkout.summary' | translate }}</div>
        <!--        <div class="row">-->
        <!--          <div class="col-6 fw-bold font-size-12"><span>Items</span>:</div>-->
        <!--          <div class="col-6 font-size-12 text-end pe-4">{{ totalItems$ | async }}</div>-->
        <!--        </div>-->

        <div class="row mb-1">
          <ng-container *ngIf="isListHasMerchantItem">
            <div class="col-6 fw-bold font-size-14">{{ 'checkout.total.amount' | translate }}:</div>

            <div class="col-6 fw-bold font-size-14 text-end">${{ (totalPriceMerchantValue / 100).toFixed(2) }}</div>
          </ng-container>

          <ng-container *ngIf="!isListHasMerchantItem">
            <div class="col-6 fw-bold font-size-14">{{ 'checkout.total.point' | translate }}:</div>
            <div class="col-6 fw-bold font-size-14 text-end">{{ totalPoints | FortressCurrencyPipe: 'PTS' }}</div>
          </ng-container>
        </div>
      </div>

      <!--      Purchase Card-->
      <ng-container *ngIf="cardInfor.CardNumber">
        <div class="container address-form mb-3">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="text-black font-size-14 fw-bold">{{ 'checkout.payment.type' | translate }}</div>
            <!--            <div class="font-size-14 change-payment">{{ 'checkout.change_payment_type' | translate }}</div>-->
          </div>

          <div class="font-size-14 mb-1">
            {{ 'checkout.card_type' | translate }}
          </div>
          <div class="d-flex align-items-center">
            <div class="card-type-icon">
              <ng-container [ngSwitch]="cardInfor.CardType">
                <img class="mastercard-logo" *ngSwitchCase="1" src="assets/images/payment-icons/_Mastercard.svg" />
                <img class="mastercard-logo" *ngSwitchCase="2" src="assets/images/payment-icons/_AMEX.svg" />
                <img class="visa-logo" *ngSwitchCase="3" src="assets/images/payment-icons/_Visa.png" />
                <img class="mastercard-logo" *ngSwitchCase="4" src="assets/images/payment-icons/_Discover.svg" />
                <img class="mastercard-logo" *ngSwitchCase="5" src="assets/images/payment-icons/_JCB.svg" />
                <img class="mastercard-logo" *ngSwitchCase="7" src="assets/images/payment-icons/_DinnersClub.svg" />
                <img class="mastercard-logo" *ngSwitchCase="8" src="assets/images/payment-icons/_UnionPay.svg" />
                <div class="mastercard-logo" *ngSwitchDefault></div>
              </ng-container>
            </div>

            <div class="card-number ms-2">{{ cardInfor.CardNumber | creditCardNumber }}</div>
          </div>
        </div>
      </ng-container>

      <div class="px-0 mt-2" *ngIf="items$ | async as items">
        <ng-container *ngIf="cardInfor.CardNumber">
          <button
            class="btn btn-success fw-bold mt-2 w-100 font-size-14"
            (click)="payNow()"
            [disabled]="items.length == 0 || loading"
          >
            <span *ngIf="!loading">
              {{ items.length === 0 ? ('checkout.empty_cart' | translate) : ('checkout.purchase.now' | translate) }}
            </span>
            <fgbcl-loading [size]="'small'" *ngIf="loading"></fgbcl-loading>
          </button>
        </ng-container>

        <ng-container *ngIf="!cardInfor.CardNumber">
          <button class="btn btn-success fw-bold mt-2 w-100 font-size-14" (click)="moveToPayment()">
            {{ 'checkout.no.card.link' | translate }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
