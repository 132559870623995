<nav class="nav nav-secondary">
  <a
    class="nav-item"
    [routerLink]="['/games/quiz']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'game.nav.quiz.screenreader' | translate : { fgbdefault: 'Move to Trivia page' } }}"
  >
    {{ 'games.nav.trivia' | translate }}
  </a>

  <a
    class="nav-item"
    [routerLink]="['/games/polls']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'game.nav.polls.screenreader' | translate : { fgbdefault: 'Move to Polls page' } }}"
  >
    {{ 'games.nav.polls' | translate }}
  </a>
</nav>
