<div class="maintenance-page bg-black h-100 d-flex align-items-center flex-column">
  <img class="logo mb-4" draggable="false" src="assets/images/logo.svg" />
  <div class="text-center mt-lg-3">
    <div class="text-white icon material-icons mb-4">engineering</div>
    <h5 class="text-white mb-4">{{ 'maintenance.page.title' | translate }}</h5>
    <div
      class="text-white maintenance-text font-size-14 mb-4 text-center"
      [innerHTML]="'maintenance.page.details' | translate | markdownTranslate"
    ></div>
  </div>
</div>
