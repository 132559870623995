<nav class="navbar bg-black text-white navbar-expand-lg d-none d-lg-flex shadow px-3">
  <a
    class="navbar-brand py-0"
    [routerLink]="['/']"
    routerLinkActive="router-link-active"
    attr.aria-label="{{ 'nav.home.page.screenreader' | translate: { fgbdefault: 'Move to home page' } }}"
  >
    <label id="homeLink" class="accessibility-only">Go To Home Page</label>
    <img alt="" class="ms-1 h-100" aria-labelledby="homeLink" src="/assets/images/nav-logo.svg" draggable="false" />
  </a>

  <button
    class="navbar-toggler"
    type="button"
    (click)="isCollapsed = !isCollapsed"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    attr.aria-label="{{ 'nav.toggle.screenreader' | translate }}"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- Left Side Content -->
  <div class="navbar-collapse" [ngbCollapse]="isCollapsed" id="navbarSupportedContent">
    <div class="nav-container">
      <ul class="navbar-nav" #greedyNav>
        <li class="nav-item active more-dropdown">
          <div ngbDropdown #ddMore="ngbDropdown" [autoClose]="'outside'" role="group">
            <a class="nav-link mx-1" ngbDropdownToggle focusTabBtn>
              <span>{{ 'home.nav.hawks.bucks' | translate }}</span>
            </a>
            <div ngbDropdownMenu class="shadow text-black" (click)="closeDropDown(ddMore)">
              <div>
                <a [routerLink]="['/hawksbucks']" routerLinkActive="router-link-active" class="text-white ps-3">
                  {{ 'nav.add.hawks.bucks' | translate }}
                </a>
              </div>
              <div class="py-1">
                <a [routerLink]="['/payment']" routerLinkActive="router-link-active" class="text-white ps-3">
                  {{ 'nav.payment.card' | translate }}
                </a>
              </div>
              <div>
                <a [routerLink]="['/transfer']" routerLinkActive="router-link-active" class="text-white ps-3">
                  {{ 'nav.transfer.hawks.bucks' | translate }}
                </a>
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item active">
          <a
            class="nav-link mx-1"
            [routerLink]="['/rewards']"
            routerLinkActive="router-link-active"
            attr.aria-label="{{ 'nav.rewards.screenreader' | translate: { fgbdefault: 'Move to rewards page' } }}"
            >{{ 'nav.rewards' | translate }}</a
          >
        </li>
        <li class="nav-item active">
          <a
            class="nav-link mx-1"
            [routerLink]="['/challenges']"
            routerLinkActive="router-link-active"
            attr.aria-label="{{ 'nav.challenges.screenreader' | translate: { fgbdefault: 'Move to challenge page' } }}"
            >{{ 'nav.challenges' | translate }}</a
          >
        </li>
        <li class="nav-item active">
          <a
            class="nav-link mx-1"
            [routerLink]="['/games/quiz']"
            routerLinkActive="router-link-active"
            attr.aria-label="{{ 'nav.games.screenreader' | translate: { fgbdefault: 'Move to games page' } }}"
            >{{ 'nav.games' | translate }}</a
          >
        </li>
        <li class="nav-item active more-dropdown">
          <div ngbDropdown #ddMore="ngbDropdown" [autoClose]="'outside'" role="group">
            <a class="nav-link mx-1" ngbDropdownToggle focusTabBtn>
              <span>{{ 'nav.parking' | translate }}</span>
            </a>
            <div ngbDropdownMenu class="shadow" (click)="closeDropDown(ddMore)">
              <div>
                <a href="{{ 'nav.parking.view.url' | translate }}" target="_blank" class="text-white ps-3">
                  {{ 'nav.parking.view' | translate }}
                </a>
              </div>
              <div class="py-1">
                <a href="{{ 'nav.parking.buy.url' | translate }}" target="_blank" class="text-white ps-3">
                  {{ 'nav.parking.buy' | translate }}
                </a>
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item active more-dropdown">
          <div ngbDropdown #ddMore="ngbDropdown" [autoClose]="'outside'" role="group">
            <a class="nav-link mx-1" ngbDropdownToggle focusTabBtn>
              <span>{{ 'nav.tickets' | translate }}</span>
            </a>
            <div ngbDropdownMenu class="shadow" (click)="closeDropDown(ddMore)">
              <div>
                <a [routerLink]="['/hawksbucks']" routerLinkActive="router-link-active" class="text-white ps-3">
                  {{ 'nav.tickets.view' | translate }}
                </a>
              </div>
              <div>
                <a [routerLink]="['/transfer']" routerLinkActive="router-link-active" class="text-white ps-3">
                  {{ 'nav.tickets.transfer' | translate }}
                </a>
              </div>
              <div>
                <a href="{{ 'nav.tickets.upgrade.url' | translate }}" target="_blank" class="text-white ps-3">
                  {{ 'nav.tickets.upgrade' | translate }}
                </a>
              </div>
              <div>
                <a href="{{ 'nav.tickets.buy.url' | translate }}" target="_blank" class="text-white ps-3">
                  {{ 'nav.tickets.buy' | translate }}
                </a>
              </div>
              <div class="py-1">
                <a href="{{ 'nav.tickets.sell.url' | translate }}" target="_blank" class="text-white ps-3">
                  {{ 'nav.tickets.sell' | translate }}
                </a>
              </div>
              <div>
                <a href="{{ 'nav.tickets.faq.url' | translate }}" target="_blank" class="text-white ps-3">
                  {{ 'nav.tickets.faq' | translate }}
                </a>
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item active more-dropdown">
          <div ngbDropdown #ddMore="ngbDropdown" [autoClose]="'outside'" role="group">
            <a class="nav-link mx-1" ngbDropdownToggle focusTabBtn>
              <span>{{ 'nav.help' | translate }}</span>
            </a>
            <div ngbDropdownMenu class="shadow" (click)="closeDropDown(ddMore)">
              <div>
                <a [routerLink]="['/faq']" routerLinkActive="router-link-active" class="text-white ps-3">
                  {{ 'nav.faq' | translate }}
                </a>
              </div>
              <div class="py-1">
                <a [routerLink]="['/contact']" routerLinkActive="router-link-active" class="text-white ps-3">
                  {{ 'nav.contact.us' | translate }}
                </a>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- Right Side Content -->
    <div class="d-flex align-items-center flex-nowrap my-2 my-lg-0">
      <div class="balance font-size-14 ps-0">
        <div class="d-flex align-items-center" *ngIf="combinedCard$">
          <span
            class="icon material-icons currency-icons me-2"
            role="img"
            attr.aria-label="{{ 'nav.ecash.screenreader' | translate }}"
            >credit_card</span
          >
          <span class="fw-bold">{{ combinedCard$ | async | FortressCurrencyPipe }}</span>
        </div>
      </div>
      <!-- <div class="vertical-separator"></div> -->
      <!--Notifications-->
      <fgb-notification-button></fgb-notification-button>
      <!--New Dropdown (Needs Manage Tickets update before being looked at again)-->
      <div class="member" ngbDropdown #ddAccount="ngbDropdown" [autoClose]="'outside'" focusTab>
        <button
          class="nav-link btn d-flex align-items-center mx-2 ps-3 justify-content-end text-uppercase"
          id="namedropdown"
          ngbDropdownToggle
          attr.aria-label="{{ 'nav.account.dropdown.screenreader' | translate: { fgbdefault: 'Click for more account menu' } }}"
          tabindex="-1"
        >
          <div *ngIf="memberDetails$ | async as memberDetails; else memberDetailsLoading" class="user-box h-100 d-inline-block">
            <div class="font-size-14 text-start fw-bold">{{ memberDetails!.FirstName }}</div>
          </div>
        </button>

        <div
          ngbDropdownMenu
          (click)="closeDropDown(ddAccount)"
          aria-labelledby="namedropdown"
          class="shadow text-uppercase text-center border-0 dropdown-menu p-0 account-dropdown mt-2"
        >
          <a
            [routerLink]="['/account']"
            routerLinkActive="router-link-active"
            attr.aria-label="{{ 'nav.account.screenreader' | translate: { fgbdefault: 'Move to account page' } }}"
            class="d-flex align-items-center account-btn py-0 px-2 font-size-14 nav-item-fixed-height rounded-top"
            ngbDropdownItem
          >
            <span class="icon material-icons material-dropdown-icon me-2" aria-hidden="true">person</span>
            <div class="dropdown-link text-start">
              <div class="mt-2 fw-bold">Account</div>
              <div class="nav-bar-little-text text-capitalize text-start text-white" *ngIf="card$ | async as memberCard">
                {{ memberCard.ExternalRefNumber }} - {{ memberCard.ProductCode }}
              </div>
            </div>
          </a>

          <hr class="text-white w-90 mx-3 my-0" />

          <a
            [routerLink]="['/rewards/wallet']"
            routerLinkActive="router-link-active"
            attr.aria-label="{{ 'nav.wallet.screenreader' | translate: { fgbdefault: 'Move to wallet page' } }}"
            class="d-flex align-items-center account-btn py-0 px-2 nav-item-fixed-height"
            ngbDropdownItem
          >
            <span class="icon material-icons material-dropdown-icon me-2" aria-hidden="true">account_balance_wallet</span>
            <div class="dropdown-link text-start">
              <div class="font-size-14 fw-bold">{{ 'nav.wallet' | translate }}</div>
            </div>
          </a>
          <hr class="text-white w-90 mx-auto my-0" />
          <a
            href="{{ 'nav.membership.url' | translate }}"
            target="_blank"
            attr.aria-label="{{ 'nav.membership.screenreader' | translate: { fgbdefault: 'Move to membership page' } }}"
            class="d-flex align-items-center account-btn py-0 px-2 nav-item-fixed-height"
            ngbDropdownItem
          >
            <span class="icon material-icons material-dropdown-icon me-2" aria-hidden="true">badge</span>
            <div class="dropdown-link text-start">
              <div class="font-size-14 fw-bold">{{ 'nav.membership' | translate }}</div>
            </div>
          </a>
          <hr class="text-white w-90 mx-auto my-0" />
          <a
            href="{{ 'nav.membership.renewals.url' | translate }}"
            target="_blank"
            attr.aria-label="{{ 'nav.renewal.screenreader' | translate: { fgbdefault: 'Move to renewal page' } }}"
            class="d-flex align-items-center account-btn py-0 px-2 nav-item-fixed-height"
            ngbDropdownItem
          >
            <span class="icon material-icons material-dropdown-icon me-2" aria-hidden="true">autorenew</span>
            <div class="dropdown-link text-start">
              <div class="font-size-14 fw-bold">{{ 'nav.membership.renewals' | translate }}</div>
            </div>
          </a>
          <hr class="text-white w-90 mx-auto my-0" />
          <button
            class="logout-btn d-flex align-items-center px-2 nav-item-fixed-height rounded-bottom"
            (click)="logout()"
            ngbDropdownItem
            attr.aria-label="{{ 'nav.log.out.screenreader' | translate: { fgbdefault: 'Log out' } }}"
          >
            <span class="icon material-icons material-dropdown-icon me-2" aria-hidden="true">power_settings_new</span>
            <div class="dropdown-link text-start">
              <div class="py-1 text-uppercase font-size-14 fw-bold" ngbDropdownItem (click)="logout()">
                {{ 'nav.log.out' | translate: { fgbdefault: 'Log out' } }}
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</nav>

<ng-template #memberDetailsLoading>
  <div class="user-box d-block text-center text-on-primary px-4">
    <fgbcl-loading [size]="'small'"></fgbcl-loading>
  </div>
</ng-template>
