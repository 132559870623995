<fgb-game-nav></fgb-game-nav>

<ng-container
  *ngIf="
    {
      polls: polls$ | async,
      predictors: predictors$ | async,
      quizzes: quizzes$ | async,
      scorePredictors: scorePredictors$ | async,
      currentGames: currentGames$ | async
    } as surveys;
    else noSurveys
  "
>
  <ng-container
    *ngIf="
      !!surveys.quizzes.length || !!surveys.polls.length || !!surveys.predictors.length || !!surveys.currentGames.length;
      else noSurveys
    "
  >
    <!--Quiz-->
    <div class="container games">
      <div *ngIf="surveys.quizzes && !!surveys.quizzes.length">
        <div class="title">
          <h4 class="text-center text-primary">{{ 'games.page.trivia.title' | translate }}</h4>
        </div>
        <owl-carousel-o class="d-block d-lg-none" [options]="customOptions">
          <ng-container *ngFor="let quiz of surveys.quizzes">
            <ng-template carouselSlide>
              <fgb-survey-item [survey]="quiz"></fgb-survey-item>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
        <owl-carousel-o class="d-none d-lg-block" [options]="customOptions2">
          <ng-container *ngFor="let quiz of surveys.quizzes">
            <ng-template carouselSlide [width]="930">
              <fgb-survey-item [survey]="quiz"></fgb-survey-item>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
      <!--Predictor-->
      <div *ngIf="surveys.predictors && !!surveys.predictors.length">
        <div class="title">
          <h4 class="text-center text-primary mt-4">{{ 'games.page.predictor.title' | translate }}</h4>
        </div>
        <owl-carousel-o class="d-block d-lg-none" [options]="customOptions">
          <ng-container *ngFor="let predictor of surveys.predictors">
            <ng-template carouselSlide>
              <fgb-survey-item [survey]="predictor"></fgb-survey-item>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
        <owl-carousel-o class="d-none d-lg-block" [options]="customOptions2">
          <ng-container *ngFor="let predictor of surveys.predictors">
            <ng-template carouselSlide [width]="930">
              <fgb-survey-item [survey]="predictor"></fgb-survey-item>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
      <!--Polls-->
      <div *ngIf="surveys.polls && !!surveys.polls.length">
        <div class="title">
          <h4 class="text-center text-primary mt-4">{{ 'games.page.polls.title' | translate }}</h4>
        </div>
        <owl-carousel-o class="d-block d-lg-none" [options]="customOptions">
          <ng-container *ngFor="let poll of surveys.polls">
            <ng-template carouselSlide>
              <fgb-survey-item [survey]="poll"></fgb-survey-item>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
        <owl-carousel-o class="d-none d-lg-block" [options]="customOptions2">
          <ng-container *ngFor="let poll of surveys.polls">
            <ng-template carouselSlide [width]="930">
              <fgb-survey-item [survey]="poll"></fgb-survey-item>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
      <!--Score Predictor-->
      <div *ngIf="surveys.scorePredictors && !!surveys.scorePredictors.length">
        <div class="title">
          <h4 class="text-center text-primary mt-4">{{ 'games.page.score.predictor.title' | translate }}</h4>
        </div>
        <div class="score-predictor-owl d-block d-lg-none">
          <owl-carousel-o [options]="customOptions">
            <ng-container *ngFor="let scorePredictor of surveys.scorePredictors">
              <ng-template carouselSlide>
                <fgb-score-predictor-item [survey]="scorePredictor"></fgb-score-predictor-item>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
        <div class="score-predictor-owl d-none d-lg-block">
          <owl-carousel-o [options]="customOptions2">
            <ng-container *ngFor="let scorePredictor of surveys.scorePredictors">
              <ng-template carouselSlide [width]="930">
                <fgb-score-predictor-item [survey]="scorePredictor"></fgb-score-predictor-item>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </div>
      <!--Pick a Player-->
      <ng-container *ngIf="surveys.currentGames && !!surveys.currentGames.length">
        <div class="title">
          <h4 class="text-center text-primary mt-4">{{ 'games.page.pick.player.title' | translate }}</h4>
        </div>
        <owl-carousel-o class="d-block d-lg-none" [options]="customOptions">
          <ng-container *ngFor="let game of surveys.currentGames">
            <ng-template carouselSlide>
              <fgb-fantasy-game-panel
                [game]="game"
                (button)="goToDetail(game.GameId)"
                [buttonState]="hasActivePlayer(game) ? 'player-link' : 'no-player-link'"
              ></fgb-fantasy-game-panel>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
        <owl-carousel-o class="d-none d-lg-block" [options]="customOptions2">
          <ng-container *ngFor="let game of surveys.currentGames">
            <ng-template carouselSlide [width]="930">
              <fgb-fantasy-game-panel
                [game]="game"
                (button)="goToDetail(game.GameId)"
                [buttonState]="hasActivePlayer(game) ? 'player-link' : 'no-player-link'"
              ></fgb-fantasy-game-panel>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<!--Placeholder Information-->
<ng-template #noSurveys>
  <div class="no-items w-100 text-center">
    <div class="icon material-icons-two-tone no-results-placeholder-icon text-center" aria-hidden="true">extension</div>
    <div class="no-results-placeholder-title fw-bold text-primary text-uppercase">
      {{ 'games.page.placeholder.title' | translate }}
    </div>
    <div class="no-results-placeholder-text text-info mb-5">{{ 'games.page.placeholder.description' | translate }}</div>
  </div>
</ng-template>
