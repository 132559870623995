<div class="survey-answer">
  <div class="text-center points-won fw-bold text-tertiary pb-3 font-size-16 d-none d-lg-block" *ngIf="pointsWon > 0">
    +{{ pointsWon }} Pts
  </div>
  <div class="survey-answer-container py-3 mx-lg-5">
    <div
      class="col-12 p-0 my-2 my-lg-3"
      *ngFor="let answer of survey.SurveyAnswers"
      [ngClass]="{
        'col-lg-6': survey.SurveyType === surveyType.Predictor,
        'col-6 col-lg-5': survey.AnswerDisplayType == answerDisplayType.Image && survey.SurveyType === surveyType.Poll,
        'col-12 col-lg-5': survey.AnswerDisplayType != answerDisplayType.Image && survey.SurveyType === surveyType.Poll
      }"
    >
      <div
        class="survey-answer-item options-box-border"
        [ngClass]="{
          'w-95-mb': survey.AnswerDisplayType != answerDisplayType.Image,
          'survey-answer-image-item': survey.AnswerDisplayType == answerDisplayType.Image
        }"
      >
        <fgb-survey-item-answer-completed-poll-item
          [answer]="answer"
          [entryCount]="survey.SurveyEntryCount"
          [displayType]="survey.AnswerDisplayType"
        ></fgb-survey-item-answer-completed-poll-item>
      </div>
    </div>
  </div>
</div>
