<div class="full-page bg-black container-fluid w-100 p-0">
  <div class="overflow-hidden p-0">
    <div class="m-0 d-flex flex-column justify-content-lg-center wrap-page">
      <img class="logo mx-auto mb-3" src="/assets/images/logo.svg"/>
      <div class="user-login-panel text-start bg-white">
        <div class="terms-padding">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
