<div class="container mt-4 mb-2 d-lg-none">
  <fgb-barcode [showTickets]="false"></fgb-barcode>
</div>

<!--Nav-->
<fgb-home-nav></fgb-home-nav>

<div class="container mb-4">
  <div class="d-flex">
    <ng-container *ifScreenSize="screenType.Desktop">
      <div class="transfer-image text-white cursor-pointer w-100" [routerLink]="'/payment'">
        <h5 class="mb-1">{{ 'hawks.banner.add.card.title' | translate }}</h5>
        <div class="font-size-12">{{ 'hawks.banner.add.card.desc' | translate }}</div>
      </div>
    </ng-container>

    <div class="transfer-image text-white cursor-pointer w-100 ms-lg-3" [routerLink]="'/transfer'">
      <h5 class="mb-1">{{ 'hawks.banner.transfer.title' | translate }}</h5>
      <div class="font-size-12">{{ 'hawks.banner.transfer.desc' | translate }}</div>
    </div>
  </div>
</div>

<div class="container">
  <fgb-loaded-tickets></fgb-loaded-tickets>
</div>
