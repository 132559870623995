<nav class="nav nav-secondary account-navbar">
  <!--Account-->
  <a
    class="nav-item"
    [routerLink]="['/account']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.account.screenreader' | translate : { fgbdefault: 'Move to Account page' } }}"
  >
    <label>{{ 'account.nav.profile' | translate }}</label>
    <div class="border-for-label nav-border-short"></div>
  </a>

  <!--Transfer-->
  <a
    class="nav-item hawks-nav"
    [routerLink]="['/transfer']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.transfer.screenreader' | translate : { fgbdefault: 'Move to transfer page' } }}"
  >
    <label>{{ 'account.nav.transfer' | translate }}</label>
    <div class="border-for-label nav-border-long"></div>
  </a>

  <!--Payment-->
  <a
    class="nav-item payment-nav"
    [routerLink]="['/payment']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.payment.screenreader' | translate : { fgbdefault: 'Move to payment page' } }}"
  >
    <label>{{ 'account.nav.payment' | translate }}</label>
    <div class="border-for-label nav-border-long"></div>
  </a>

  <!--Payment History-->
  <a
    class="nav-item d-lg-none"
    [routerLink]="['/ecashhistory']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.ecashhistory.screenreader' | translate : { fgbdefault: 'Move to ecash history page' } }}"
  >
    <label>{{ 'account.nav.ecashhistory' | translate }}</label>
    <div class="border-for-label nav-border-long"></div>
  </a>
</nav>
