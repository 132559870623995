<div class="card border border-disabled my-4 rounded">
  <div class="card-content px-3">
    <div class="row">
      <div class="col-lg-5">
        <h5 class="mt-0 mb-lg-0 mb-3 fw-bold text-secondary text-capitalize">
          {{ 'voucher.title' | translate }}
        </h5>
      </div>
      <div class="col-lg-4">
        <span class="input-group">
          <input
            type="text"
            [(ngModel)]="voucherCode"
            placeholder="INSERT CODE HERE"
            class="form-control text-center border-0 px-5 px-lg-0"
            (keyup.enter)="redeemVoucher()"
          />
          <div class="input-group-append">
            <button
              class="btn btn-primary text-white fw-bold p-0"
              type="button"
              (click)="redeemVoucher()"
              [disabled]="disableButton"
            >
              <span class="icon material-icons"> keyboard_arrow_right </span>
            </button>
          </div>
        </span>
        <div class="text-center">
          <fgbcl-errors></fgbcl-errors>
        </div>

        <div class="result" *ngIf="redemptionResult && voucherCode && sucessfulRedemption">
          <div class="font-size-12 mt-1 text-success text-center">
            {{ redemptionResult }}
          </div>
        </div>
        <div class="result" *ngIf="redemptionResult && !voucherCode">
          <div class="font-size-12 mt-1 text-black text-center">
            {{ redemptionResult }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
