<div class="container success-page" *ngIf="transferAccounts$ | async as transferAccounts">
  <div class="border rounded">
    <div class="p-3 d-flex align-items-center justify-content-center">
      <h5 class="text-success mb-0">{{ 'ecash.transfer.success.title' | translate }}</h5>
    </div>
    <hr class="mx-2 my-0"/>
    <div class="success-container">
      <div class="font-size-14 fw-bold mt-lg-1 mb-lg-4 pb-lg-2 my-4">
        <span class="text-primary">{{ transferAccounts.EmailAddress }}</span> {{ 'ecash.transfer.message.success' | translate }}
        <span class="text-success">{{ transferAccounts.TransferAmount | FortressCurrencyPipe : 'USD' }}</span>
      </div>
      <a class="btn btn-primary fw-bold d-lg-block d-none mx-auto" routerLink="/transfer"> {{ 'ecash.transfer.success.button' | translate }} </a>
    </div>
    <a class="btn btn-primary fw-bold d-lg-none d-block mx-auto mb-3" routerLink="/transfer"> {{ 'ecash.transfer.success.button' | translate }} </a>
  </div>
</div>
