import { Component } from '@angular/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';

@Component({
  selector: 'fgb-hawks-bucks-page',
  templateUrl: './hawks-bucks-page.component.html',
  styleUrl: './hawks-bucks-page.component.scss',
})
export class HawksBucksPageComponent {
  screenType = ScreenType;
}
