import { Component, OnInit, Input } from '@angular/core';
import { Barcode, BarcodeQuery, MemberCardQuery, MemberDetails, MemberQuery, CardToken, CardTokenQuery } from '@fgb/core';
import { BarcodeService } from '@fgb/core';
import { Observable } from 'rxjs';
import { MemberCard } from '@fgb/core';
import { config } from '@fgb/core';
import { BalanceQuery } from '@fgb/core';

@Component({
  selector: 'fgb-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss'],
})
export class BarcodeComponent implements OnInit {
  @Input() showTickets: boolean = true;
  barcode$: Observable<Barcode>;
  card$: Observable<MemberCard | undefined>;
  memberDetails$: Observable<MemberDetails | undefined>;
  ecash$: Observable<number | undefined>;
  cardToken$: Observable<CardToken | undefined>;

  constructor(
    private barcodeService: BarcodeService,
    private barcodeQuery: BarcodeQuery,
    private balanceQuery: BalanceQuery,
    private memberCardQuery: MemberCardQuery,
    private memberQuery: MemberQuery,
    private cardTokenQuery: CardTokenQuery
  ) {}

  ngOnInit() {
    this.barcodeService.fetchBarcode().toPromise();
    this.barcode$ = this.barcodeQuery.select();
    this.card$ = this.memberCardQuery.selectCurrentCard();
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.ecash$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
    this.cardToken$ = this.cardTokenQuery.selectDefaultCard();
  }
}
