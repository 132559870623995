<div class="container mt-3">
  <div class="d-flex flex-column align-items-end position-relative">
    <div
      class="filter-result d-flex align-items-center flex-row p-2 cursor-pointer"
      (click)="toggleShowFilterOption()"
      [attr.data-bs-toggle]="screenSize <= 600 ? 'offcanvas' : null"
      data-bs-target="#offcanvasBottom"
      aria-controls="offcanvasBottom"
    >
      <p class="mb-0 mr-2 font-size-14">
        {{ 'reward.filter_modal.filter' | translate }}:
        <span class="text-primary" *ngIf="filterTypesList.length === 1">{{ labelString | translate }}</span>
        <span class="text-primary" *ngIf="filterTypesList.length !== 1">{{ labelString }}</span>
      </p>
      <span class="material-icons material-symbols-outlined"> keyboard_arrow_down </span>
    </div>

    <ng-container *ngIf="screenSize > 600">
      <div class="filter-select-option p-3 position-absolute bg-white d-none d-lg-block" *ngIf="showFilterOption">
        <div class="filter-label mb-2 fw-bold font-size-14 pb-2">
          {{ 'reward.filter_modal.reward_type' | translate }}
        </div>
        <div class="options-list">
          <div class="d-flex align-items-center" *ngFor="let option of filterTypesOptions">
            <input
              class="form-check-input cursor-pointer"
              id="{{ option.label }}"
              type="checkbox"
              [checked]="selectedValues.includes(option.value)"
              (change)="handleChangeSelectType(option.value)"
              [disabled]="option.value === 'all' && selectedValues.toString() === ['all'].toString()"
            />
            <label for="{{ option.label }}" class="cursor-pointer fw-normal font-size-14">{{ option.label | translate }}</label>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="screenSize <= 600">
      <div
        class="offcanvas offcanvas-attribute offcanvas-bottom d-lg-none offcanvas-filter-modal"
        tabindex="777"
        id="offcanvasBottom"
        aria-labelledby="offcanvasBottomLabel"
      >
        <div class="d-flex flex-column pb-2" *ngIf="showOffCanvas">
          <div class="p-2 bg-white offcanvas-filter-modal">
            <div class="d-flex justify-content-between align-items-center filter-modal-header offcanvas-header px-0 mx-2">
              <div class="filter-label fw-bold font-size-14">
                {{ 'reward.filter_modal.reward_type' | translate }}
              </div>
              <span class="material-icons material-symbols-outlined text-info" data-bs-dismiss="offcanvas" aria-label="Close"> close </span>
            </div>
            <div class="options-list filter-modal-option offcanvas-body px-0 mx-2">
              <div class="d-flex align-items-center" *ngFor="let option of filterTypesOptions">
                <input
                  class="form-check-input cursor-pointer"
                  id="{{ option.label }}"
                  type="checkbox"
                  [checked]="defaultSelectedValues.includes(option.value)"
                  (change)="handleChangeSelectType(option.value)"
                  [disabled]="option.value === 'all' && defaultSelectedValues.toString() === ['all'].toString()"
                />
                <label for="{{ option.label }}" class="cursor-pointer fw-normal font-size-14">{{ option.label | translate }}</label>
              </div>
            </div>
            <div class="filter-button d-flex justify-content-center align-items-center">
              <button
                class="apply-filter-btn btn btn-primary"
                (click)="handleApplyFilters()"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBottom"
                aria-controls="offcanvasBottom"
              >
                {{ 'reward.filter_modal.apply_filter' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
