<div class="container mt-4 mb-2 d-lg-none">
  <fgb-barcode></fgb-barcode>
</div>

<fgb-account-nav></fgb-account-nav>

<div class="container d-none d-lg-block">
  <div class="row">
    <div class="col-12 col-lg-6 order-lg-1 order-1">
      <h5 class="text-left mb-3 mt-lg-0">{{ 'account.member.card.title' | translate }}</h5>
      <fgb-member-card></fgb-member-card>

      <h5 class="my-3 pt-3">{{ 'questions.subheading' | translate }}</h5>
      <fgbcl-edit-questions></fgbcl-edit-questions>

      <a class="card bg-black tm-widget text-white mt-4 w-100 mb-5" href="{{ 'tm.account.manager.link' | translate }}">
        <h5 class="text-uppercase">{{ 'account.tm.widget.title' | translate }}</h5>
        <img src="/assets/images/tm-logo.svg" />
      </a>
    </div>
    <div class="col-12 col-lg-6 order-lg-2 order-2">
      <h5 class="text-left my-3 mt-lg-0">{{ 'account.details.title' | translate }}</h5>
      <fgb-account-details></fgb-account-details>
    </div>
  </div>
</div>

<div class="container d-lg-none">
  <h5 class="my-3">{{ 'account.details.title' | translate }}</h5>
  <fgb-account-details></fgb-account-details>

  <h5 class="my-3 pt-3">{{ 'questions.subheading' | translate }}</h5>
  <fgbcl-edit-questions></fgbcl-edit-questions>

  <a class="card bg-black tm-widget text-white mt-4 w-100 mb-5" href="{{ 'tm.account.manager.link' | translate }}">
    <h5 class="text-uppercase">{{ 'account.tm.widget.title' | translate }}</h5>
    <img src="/assets/images/tm-logo.svg" />
  </a>
</div>
