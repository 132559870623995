<nav class="nav nav-secondary">
  <!--Home-->
  <a
    class="nav-item"
    [routerLink]="['/']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'home.nav.home.screenreader' | translate: { fgbdefault: 'Move to home page' } }}"
  >
    {{ 'home.nav.home' | translate }}
    <!-- <div class="border-for-label"></div> -->
  </a>
  <!--Hawks Bucks-->
  <a
    class="nav-item"
    [routerLink]="['/hawksbucks']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'home.nav.hawks.bucks.screenreader' | translate: { fgbdefault: 'Move to hawks bucks page' } }}"
  >
    {{ 'home.nav.hawks.bucks' | translate }}
    <!-- <div class="border-for-label"></div> -->
  </a>
</nav>
