<div class="container mt-4 mb-2 d-block d-lg-none">
  <fgb-barcode></fgb-barcode>
</div>

<fgb-account-nav></fgb-account-nav>

<div class="container">
  <h5 class="font-size-22">{{ 'transfer.credit.title' | translate }}</h5>
  <div
    class="d-flex align-items-center font-weight-bold font-size-14 transfer-credit-description"
    [innerHTML]="'transfer.credit.description' | translate | markdownTranslate"
  ></div>

  <div class="arena-cash-box bg-white rounded">
    <fgb-transfer-arena-cash></fgb-transfer-arena-cash>
  </div>

  <fgb-transaction-history
    [transactionTypes]="[transactionTypes.ecash, transactionTypes.payment]"
    [ecashView]="true"
    [isTransferPage]="true"
  ></fgb-transaction-history>
</div>
