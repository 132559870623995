<nav class="nav nav-secondary mixed-loyalty-nav my-0 bg-white">
  <a
    attr.aria-label="{{ 'rewards.nav.featured.screenreader' | translate }}"
    class="nav-item"
    [routerLink]="['/rewards']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    {{'rewards.nav.rewards' | translate}}
  </a>

  <a
    class="nav-item"
    [routerLink]="['/rewards/wallet']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'rewards.nav.wallet.screenreader' | translate : { fgbdefault: 'Move to Wallet page' } }}"
  >
    {{ 'rewards.nav.wallet' | translate }}
  </a>
</nav>
