<ng-container *ngIf="memberCard$ | async as memberCard; else loadingRef">
  <h2 class="accessibility-only">Member Card</h2>
  <div class="member-card border rounded mb-3 mb-lg-0">
    <div class="d-flex flex-column justify-content-between h-100">
      <!--Name and ID-->
      <div class="card-id text-start d-flex align-items-center">
        <div>
          <p class="mb-0 card-name text-primary" *ngIf="memberDetails$ | async as memberDetails">
            {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
          </p>
          <div class="d-flex font-size-10">
            <div>{{ memberCard.ExternalRefNumber }} - {{ memberCard.ProductCode }}</div>
          </div>
          <div class="text-black d-flex font-size-14 pt-4 align-items-center" *ngIf="ecash$">
            <div class="me-1">{{ 'member.card.ecash.balance' | translate }}</div>
            <label class="mb-0"> {{ ecash$ | async | FortressCurrencyPipe }}</label>
          </div>
        </div>
        <div>
          <img class="mb-3 logo" [height]="80" [width]="78" src="assets/images/logo.svg" alt="" draggable="false" />
          <br />
          <ng-container *ngIf="cardToken$ | async as cardToken">
            <img
              class="ms-2 chase-logo"
              *ngIf="cardToken.CardIssuer == 'CHASE'"
              [height]="12"
              src="assets/images/payment-icons/chase.png"
              alt=""
              draggable="false"
            />
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <fgb-ecash-button></fgb-ecash-button>
</ng-container>

<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
