<div class="container mt-4 mb-2 d-lg-none">
  <fgb-barcode></fgb-barcode>
</div>

<!--Nav-->
<fgb-home-nav></fgb-home-nav>

<!-- Cards -->
<div class="container d-none d-lg-block">
  <div class="row mt-4">
    <div class="col-lg-6">
      <fgb-member-card></fgb-member-card>
    </div>
    <div class="col-lg-6">
      <div class="card add-hawks-card text-center">
        <div class="d-flex align-items-center justify-content-center">
          <span class="me-3 icon material-icons" role="img">add_card</span>
          <span class="add-hawks-label fw-bold"> {{ 'widget.desc.heading' | translate }}</span>
        </div>
        <p class="font-size-12">
          {{ 'widget.desc.label' | translate }}
        </p>
        <button
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLink]="['/hawksbucks']"
          class="btn btn-block fw-bold mx-5 btn-rewards-redeem"
        >
          {{ 'widget.view.all' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!--Vouchers-->
<div class="container mt-4">
  <fgb-vouchers></fgb-vouchers>
</div>

<!--Scorecard carousel-->
<div class="mt-4">
  <fgbcl-scorecard-carousel [link]="'challenges'" [icon]="'chevron_right'" [showAllView]="true"></fgbcl-scorecard-carousel>
</div>
<!-- Featured Rewards -->
<div class="container">
  <fgbcl-featured-rewards></fgbcl-featured-rewards>
</div>
<!-- Offers -->
<fgbcl-campaigns-offers-list
  [showAllOffers]="true"
  (dataChange)="onCampaignOfferListChange($event)"
></fgbcl-campaigns-offers-list>

<div class="container">
  <!-- Featured Games -->
  <fgb-featured-surveys></fgb-featured-surveys>

  <!-- Announcements -->
  <fgbcl-announcements></fgbcl-announcements>
</div>
