<div class="survey-container overflow-hidden rounded quiz">
  <div class="survey-header row">
    <div class="border-0 bg-quiz col-6 px-0">
      <img *ngIf="survey.ImageURL" class="w-100 h-100" src="{{ survey.ImageURL | contentImage : '23' }}" alt="{{ survey.ImageURL }}" />
      <div *ngIf="!survey.ImageURL" class="no-img text-white h-100 d-flex flex-column justify-content-center align-items-center py-4">
        <div class="quiz-placeholder-title">{{ 'trivia.noimage.placeholder.title' | translate }}</div>
      </div>
    </div>
    <div class="survey-info col-6">
      <div class="d-flex justify-content-between text-secondary">
        <div class="d-flex align-items-center">
          <div class="icon material-icons" aria-hidden="true">live_help</div>
        </div>
      </div>
      <div class="survey-title font-size-14 line-height-18">
        <div>{{ survey.SurveyQuestion }}</div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!survey.ComingSoon">
    <ng-container *ngIf="survey.HasBeenAnsweredByUser; else answer">
      <fgb-survey-item-answer-completed-quiz [survey]="survey"></fgb-survey-item-answer-completed-quiz>
    </ng-container>
    <ng-template #answer>
      <fgb-survey-item-answer [survey]="survey"></fgb-survey-item-answer>
    </ng-template>
  </ng-container>
</div>
