import { Component, OnInit, Input } from '@angular/core';
import { RewardsWalletItem } from '@fgb/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-wallet-page',
  templateUrl: './wallet-page.component.html',
  styleUrls: ['./wallet-page.component.scss'],
})
export class WalletPageComponent implements OnInit {
  isList: boolean = true;
  walletItemsUnclaimed$: Observable<RewardsWalletItem[]>;
  @Input() walletItems: RewardsWalletItem[];

  constructor() {}

  async ngOnInit() {}
}
