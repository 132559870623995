<div class="survey-answer text-center" [ngClass]="hasSelectedCorrect ? 'my-lg-5 my-2' : 'my-2'">
  <div class="row">
    <div class="col-12 col-lg-6">
      <div class="survey-answer-container text-answer text-white row">
        <div [ngClass]="hasSelectedCorrect ? 'col-12' : 'col-12'">
          <div class="col-12" *ngIf="!hasSelectedCorrect">
            <div class="survey-answer-item bg-success correct my-3">
              <div class="answer-text text-center px-3">
                <span>{{ correctAnswer.Title }}</span>
              </div>
            </div>
          </div>
          <div
            class="survey-answer-item my-3"
            [ngClass]="hasSelectedCorrect ? 'correct' : 'incorrect '"
            *ngFor="let answer of selectedAnswers"
          >
            <div class="answer-text text-center px-3">
              <span>{{ answer.Title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <ng-container *ngIf="hasSelectedCorrect; else incorrect">
        <div class="survey-message text-success">
          <div>
            <ng-container *ngIf="pointsWon; else noPoints">
              <div class="text-center fw-bold font-size-18 mt-4 mt-lg-0">{{ 'trivia.correct.answer' | translate }}</div>
            </ng-container>
            <ng-template #noPoints>
              <p class="text-center fw-bold font-size-18 mt-4 mt-4 mt-lg-0">{{ 'trivia.correct.answer' | translate }}</p>
            </ng-template>
          </div>
        </div>
      </ng-container>
      <ng-template #incorrect>
        <div class="survey-message text-danger text-center">
          <div>
            <div class="text-center fw-bold font-size-18 my-lg-0 my-2">{{ 'trivia.wrong.answer' | translate }}</div>
            <div class="text-center points-won">{{ 'trivia.wrong.answer.desc' | translate }}</div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
