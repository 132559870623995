import {Component, OnInit} from '@angular/core';
import { zip } from 'rxjs';
import { LottoService, MarketplaceService } from '@fgb/core';

@Component({
  selector: 'fgb-rewards-page',
  templateUrl: './rewards-page.component.html',
  styleUrls: ['./rewards-page.component.scss']
})
export class RewardsPageComponent implements OnInit {
  constructor(
    private lottoService: LottoService,
    private marketplaceService: MarketplaceService) {}

  filterTypesList: { label: string, value: string }[] = [{label: 'reward.filter_modal.all', value: 'all'}];
  selectedValues: string[] = ['all'];
  showOffCanvas: boolean = false;

  ngOnInit() {
    zip(
      this.lottoService.fetchLottos(),
      this.marketplaceService.fetchMarketplaceItems()
    ).toPromise();
  }

  handleFilterTypesListEmitter(filterTypes: {label: string, value: string}[]) {
    this.filterTypesList = [...filterTypes];
  }

  handleSelectedValuesEmitter(selectedValues: string[]) {
    this.selectedValues = [...selectedValues];
  }

  handleShowOffCanvasEmitter(showOffCanvas: boolean) {
    this.showOffCanvas = showOffCanvas;
  }
}
