<div class="container d-lg-none d-block mt-4 mb-2">
  <fgb-barcode></fgb-barcode>
</div>

<fgb-account-nav></fgb-account-nav>

<div class="container d-lg-block d-flex flex-column">
  <fgbcl-ecash-description [googleIconName3]="'local_activity'" class="order-2"></fgbcl-ecash-description>
  <div class="order-1">
    <div class="d-flex align-items-end mb-2">
      <h2 class="payment-cards-title my-0 pe-3">{{ 'payment.cards.title' | translate }}</h2>
      <span class="font-size-12">Powered By: <img src="/assets/images/payment-icons/chase.png" /> </span>
    </div>
    <fgbcl-card-token-list
      [regex]="regex"
      [provider]="provider"
      [redirectUrl]="'/cardtoken/result'"
      [failureUrl]="'/cardtoken/failure'"
      [cardIssuerProvider]="'payment.provider.name' | translate"
    ></fgbcl-card-token-list>
  </div>
  <div class="d-lg-block d-none">
    <fgb-transaction-history
      [transactionTypes]="[transactionTypes.ecash, transactionTypes.payment]"
      [ecashView]="true"
    ></fgb-transaction-history>
  </div>
</div>
